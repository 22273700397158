import { useContext } from "react";
import { AppContext } from "../App";
import { useSelector } from "react-redux";

export default function useTableUtils() {
  const { rowSelectForm, pageSize } = useContext(AppContext);
  const { data, applicationData, salesforceData } = useSelector((state) => state.app);

  const refreshTaggingFields = (data, currentPage = 1, table) => {
    const formUpdates = rowSelectForm.getFieldsValue();
    for (let i = (currentPage - 1) * pageSize; i < Math.min(pageSize, data.length); i++) {
      let checkbox = document.getElementById(`check-row-${table}-${i}`);
      if (checkbox) checkbox.checked = false;
      formUpdates[`QualifyAsNPS-${table}-${i}`] = data[i].QualifyAsNPS;
      formUpdates[`NPSPercentageNew-${table}-${i}`] = data[i].NPSPercentageNew;
      formUpdates[`NPSCriteria-${table}-${i}`] = data[i].NPSCriteria;
      formUpdates[`NPSPercentageRenew-${table}-${i}`] = data[i].NPSPercentageRenew;
      formUpdates[`SustnAdvgedSales-${table}-${i}`] = data[i].SustnAdvgedSales;
      formUpdates[`SustnAdvgedSalesPercentage-${table}-${i}`] = data[i].SustnAdvgedSalesPercentage;
      formUpdates[`NPSYear-${table}-${i}`] = data[i].NPSYear;
      formUpdates[`Project-${table}-${i}`] = data[i].Project;
      formUpdates[`Commentary-${table}-${i}`] = data[i].Commentary;
    }
    rowSelectForm.setFieldsValue(formUpdates);
  };
  const getDataFromStore = (page, table) => {
    if (page === "SAP") {
      if (table === 1) return data;
      else return applicationData;
    } else return salesforceData;
  };
  return { refreshTaggingFields, getDataFromStore };
}
