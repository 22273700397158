import { Button, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { columnCategories } from "../utils/columns";
import "react-table-filter/lib/styles.css";
import React, { useContext, useEffect } from "react";
import { setUpdatesTwo, updateSalesForceData } from "../redux/actions/appActions";
import Loading from "./Loading";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { AppContext, TableFilterContext } from "../App";
import { getColumnFilterCookies, resetColumnFilters } from "../utils/cookies";
import useTagging from "../hooks/useTagging";
import FinalTable from "./FinalTable";
import { AccessNotFound, AppError, ErrorPage } from "./ErrorPage";
import useData from "../hooks/useData";
import useTableUtils from "../hooks/useTableUtils";
import { getYearOptions } from "../utils/monthUtils";
import { updateUserSelection } from "../redux/actions/userSelectionActions";

function SalesforceBody() {
  const { columns, salesforceData, duplicateSalesforceData, filters, appError, updatesTwo } = useSelector((state) => state.app);
  const { token } = useSelector((state) => state.auth);
  const { salesYearSf } = useSelector((state) => state.selections);

  const dispatch = useDispatch();
  const { tableRef, setTableRef, npsTableRef, setNPSTableRef } = useContext(TableFilterContext);

  const { submitSalesforceTagging } = useTagging();
  const { getSalesForceData } = useData();

  const yearOptions = getYearOptions();

  const {
    rowSelectForm,
    rowsToBeUpdatedForTableTwo,
    setRowsToBeUpdatedForTableTwo,
    pageSize,
    currentPageTwo,
    setCurrentPageTwo,
    setFilterDrawerOpen,
    toolbarForm,
  } = useContext(AppContext);
  const { refreshTaggingFields } = useTableUtils();

  useEffect(() => {
    dispatch(setUpdatesTwo({}));
    rowSelectForm.resetFields();
    setCurrentPageTwo(1);
    toolbarForm.resetFields();
    setRowsToBeUpdatedForTableTwo([]);
    return () => {
      dispatch(setUpdatesTwo({}));
      rowSelectForm.resetFields();
      setCurrentPageTwo(1);
      toolbarForm.resetFields();
      setRowsToBeUpdatedForTableTwo([]);
    };
  }, []);
  useEffect(() => {
    if (token && (!salesforceData || salesforceData.length === 0)) getSalesForceData(salesYearSf);
  }, [token]);

  if (!salesforceData || !filters) return <Loading />;
  if (salesforceData?.status === 403) return <AccessNotFound />;
  if (appError) return <AppError />;

  return (
    <div className="w-full px-10 mb-20" style={{ marginTop: 20 }}>
      <div className="flex flex-row justify-between items-center mb-3">
        <span className="text-red-500 font-normal text-3xl ml-5">NPS Evaluation</span>
        <div className="flex gap-2 items-center">
          <span>Sales Year</span>
          <Select
            style={{ width: 100 }}
            placeholder="Sales Year"
            options={yearOptions.slice(0, 2)}
            value={salesYearSf}
            onChange={(year) => {
              dispatch(updateUserSelection({ salesYearSf: year }));
              getSalesForceData(year);
            }}
          />
        </div>
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => {
              resetColumnFilters(2);
              refreshTaggingFields(duplicateSalesforceData, currentPageTwo, 2);
              dispatch(updateSalesForceData(duplicateSalesforceData));
              if (tableRef) tableRef.reset(duplicateSalesforceData);
              if (npsTableRef) npsTableRef.reset(duplicateSalesforceData);
            }}
            disabled={!getColumnFilterCookies(2)}
            type="default"
            icon={<DeleteOutlined />}
          >
            Clear Filters
          </Button>
          <Button
            onClick={() => {
              submitSalesforceTagging();
            }}
            type="default"
            icon={<CheckOutlined />}
          >
            Save
          </Button>
        </div>
      </div>
      <Form form={rowSelectForm}>
        {duplicateSalesforceData && duplicateSalesforceData.length ? (
          <>
            <div className="w-full bg-white items-center shadow-xl">
              <div className="flex flex-row w-full bg-white items-center mt-0 shadow-xl px-5 pt-2">
                <h2 className="text-[1.1rem] w-[55vw]">Salesforce Data</h2>
                <h2 className="text-[1.1rem] w-[36vw] flex flex-row justify-between mb-2">{columnCategories.APPLICATION_TAGGING}</h2>
              </div>
              <FinalTable
                form={rowSelectForm}
                data={salesforceData}
                columns={columns[columnCategories.SFDC_COLUMNS]}
                npsColumns={columns[columnCategories.APPLICATION_TAGGING]}
                setCurrentPage={setCurrentPageTwo}
                pageSize={pageSize}
                currentPage={currentPageTwo}
                setRowsToBeUpdated={setRowsToBeUpdatedForTableTwo}
                rowsToBeUpdated={rowsToBeUpdatedForTableTwo}
                updates={updatesTwo}
                setUpdates={setUpdatesTwo}
                table={2} // this is table id, set to one for product data table and set to 2 for prod/customer data
                setTableRef={setTableRef}
                tableRef={tableRef}
                npsTableRef={npsTableRef}
                setNpsTableRef={setNPSTableRef}
              />
            </div>
          </>
        ) : (
          <ErrorPage setFilterDrawerOpen={setFilterDrawerOpen} />
        )}
      </Form>
    </div>
  );
}

export default React.memo(SalesforceBody);
