import { Checkbox, Drawer } from "antd";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnCategories } from "../utils/columns";
import { updateColumns } from "../redux/actions/appActions";
import { updateColumnCookies } from "../utils/cookies";
// import useAggregation from "../hooks/useAggregations";

function SideBar({ isOpen, setOpen, page = "SAP" }) {
  const { columns } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { hasAccessToApp } = useSelector((state) => state.app);
  // const { aggregateData } = useAggregation();

  const handleCheck = useCallback(
    (category, columnIndex) => {
      let entireColumns = columns;
      let idx = columns[columnCategories[category]].findIndex((col) => col.dataIndex === columnIndex);
      entireColumns[columnCategories[category]][idx].visibility = !entireColumns[columnCategories[category]][idx].visibility;
      dispatch(updateColumns(JSON.parse(JSON.stringify(entireColumns))));
      updateColumnCookies(entireColumns);
      // aggregateData(entireColumns[columnCategories[category]], columnCategories[category]);
    },
    [columns, dispatch]
  );

  const areAllSelected = useCallback(
    (category) => {
      let flag = true;
      columns[columnCategories[category]].forEach((col) => {
        flag &= col.visibility;
      });
      return flag;
    },
    [columns]
  );

  const selectAllHandler = useCallback(
    (category) => {
      let allSelected = areAllSelected(category);
      let subColumns = columns[columnCategories[category]].map((col) => ({
        ...col,
        visibility: col.dataIndex === "select" ? true : !allSelected,
      }));
      let newColumns = columns;
      newColumns[columnCategories[category]] = subColumns;
      dispatch(updateColumns(newColumns));
      updateColumnCookies(newColumns);
      // aggregateData(newColumns[columnCategories[category]], columnCategories[category]);
    },
    [areAllSelected, columns, dispatch]
  );

  return (
    <div className="mt-20 fixed">
      <Drawer
        open={isOpen && hasAccessToApp}
        onClose={() => setOpen(false)}
        placement="left"
        width={550}
        closeIcon={false}
        height={100}
        mask={true}
        style={{ margin: 0, padding: 0 }}
        rootClassName="column-sidebar"
      >
        {columns && page === "SAP" ? (
          <div className="flex flex-row gap-2">
            <div className="flex flex-col w-1/2">
              <span className="bg-gray-100 p-2 flex flex-row justify-between">
                <span>{columnCategories.PRODUCT_DATA} </span>
                <span className="text-[#57B2FC] cursor-pointer" onClick={() => selectAllHandler("PRODUCT_DATA")}>
                  Select All
                </span>
              </span>
              {columns[columnCategories.PRODUCT_DATA].map((col) => {
                if (col.dataIndex === "select")
                  return (
                    <Checkbox checked={true} key={col.dataIndex} disabled={true}>
                      {col.title}
                    </Checkbox>
                  );
                return (
                  <Checkbox checked={col.visibility} key={col.dataIndex} onClick={() => handleCheck("PRODUCT_DATA", col.dataIndex)}>
                    {col.title}
                  </Checkbox>
                );
              })}
              <span className="bg-gray-100 p-2 flex flex-row justify-between">
                <span>NPS Tagging</span>
                <span className="text-[#57B2FC] cursor-pointer" onClick={() => selectAllHandler("NPS_TAGGING_DATA")}>
                  Select All
                </span>
              </span>
              {columns[columnCategories.NPS_TAGGING_DATA].map((col) => {
                return (
                  <Checkbox checked={col.visibility} key={col.dataIndex} onClick={() => handleCheck("NPS_TAGGING_DATA", col.dataIndex)}>
                    {col.title}
                  </Checkbox>
                );
              })}
            </div>
            <div className="w-1/2">
              {columnCategories &&
                Object.keys(columnCategories).map((category) => {
                  return columnCategories[category] !== columnCategories.PRODUCT_DATA &&
                    columnCategories[category] !== columnCategories.NPS_TAGGING_DATA &&
                    columnCategories[category] !== columnCategories.SFDC_COLUMNS ? (
                    <div className="flex flex-col flex-1 " key={category}>
                      <span className="bg-gray-100 p-2 flex flex-row justify-between">
                        <span>{columnCategories[category]}</span>
                        <span className="text-[#57B2FC] cursor-pointer" onClick={() => selectAllHandler(category)}>
                          Select All
                        </span>
                      </span>
                      {columns &&
                        columns[columnCategories[category]].map((col) => {
                          if (col.dataIndex === "select")
                            return (
                              <Checkbox checked={true} key={col.dataIndex} disabled={true}>
                                {col.title}
                              </Checkbox>
                            );
                          return (
                            <Checkbox checked={col.visibility} key={col.dataIndex} onClick={() => handleCheck(category, col.dataIndex)}>
                              {col.title}
                            </Checkbox>
                          );
                        })}
                    </div>
                  ) : null;
                })}
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-between w-full gap-x-2">
            <div className="flex flex-col w-1/2">
              <span className="bg-gray-100 p-2 flex flex-row justify-between">
                <span>{"ADI Data"} </span>
                <span className="text-[#57B2FC] cursor-pointer" onClick={() => selectAllHandler("SFDC_COLUMNS")}>
                  Select All
                </span>
              </span>
              {columns[columnCategories.SFDC_COLUMNS].map((col) => {
                if (col.dataIndex === "select")
                  return (
                    <Checkbox checked={true} key={col.dataIndex} disabled={true}>
                      {col.title}
                    </Checkbox>
                  );
                return (
                  <Checkbox checked={col.visibility} key={col.dataIndex} onClick={() => handleCheck("SFDC_COLUMNS", col.dataIndex)}>
                    {col.title}
                  </Checkbox>
                );
              })}
            </div>
            <div className="flex flex-col w-1/2">
              <span className="bg-gray-100 p-2 flex flex-row justify-between">
                <span>{columnCategories.APPLICATION_TAGGING} </span>
                <span className="text-[#57B2FC] cursor-pointer" onClick={() => selectAllHandler(columnCategories.APPLICATION_TAGGING)}>
                  Select All
                </span>
              </span>
              {columns[columnCategories.APPLICATION_TAGGING].map((col) => {
                return (
                  <Checkbox checked={col.visibility} key={col.dataIndex} onClick={() => handleCheck("APPLICATION_TAGGING", col.dataIndex)}>
                    {col.title}
                  </Checkbox>
                );
              })}
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default memo(SideBar);
