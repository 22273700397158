import { Pagination } from "antd";
import NPSTable from "./NPSTable";
import Table from "./Table";
import { memo, useEffect } from "react";
import { setScrollSync } from "../utils/scrollSync";
import useTableUtils from "../hooks/useTableUtils";

function FinalTable(props) {
  const {
    columns,
    data,
    form,
    setCurrentPage,
    pageSize,
    currentPage,
    setRowsToBeUpdated,
    rowsToBeUpdated,
    updates,
    setUpdates,
    setTableRef,
    tableRef,
    npsTableRef,
    npsColumns,
    setNpsTableRef,
    table,
  } = props;
  const { refreshTaggingFields } = useTableUtils();

  useEffect(() => {
    setScrollSync(table);
  }, [data, columns, npsColumns]);
  return (
    <>
      <div className="h-[80vh] bg-white overflow-auto flex flex-row gap-2 px-5">
        <div className="w-[60vw] overflow-auto first-table-container" id={`first-table-container-${table}`}>
          {columns && (
            <Table
              form={form}
              columns={columns}
              data={data}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              currentPage={currentPage}
              setRowsToBeUpdated={setRowsToBeUpdated}
              rowsToBeUpdated={rowsToBeUpdated}
              table={table} // this is table id, set to one for product data table and set to 2 for prod/customer data
              setTableRef={setTableRef}
              tableRef={tableRef}
              npsTableRef={npsTableRef}
            />
          )}
        </div>
        <div className="w-[40vw] overflow-auto second-table-container" id={`second-table-container-${table}`}>
          {columns && (
            <NPSTable
              key="SAP-PRODUCT"
              columns={npsColumns}
              data={data}
              setCurrentPage={setCurrentPage}
              form={form}
              pageSize={pageSize}
              currentPage={currentPage}
              setRowsToBeUpdated={setRowsToBeUpdated}
              table={table}
              updates={updates}
              setUpdates={setUpdates}
              setTableRef={setNpsTableRef}
              npsTableRef={npsTableRef}
              tableRef={tableRef}
            />
          )}
        </div>
      </div>
      <div className="w-full flex flex-row justify-center items-center py-5 ">
        <Pagination
          defaultCurrent={1}
          total={data?.length}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={pageSize}
          showQuickJumper
          current={currentPage}
          showSizeChanger={false}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            refreshTaggingFields(data, currentPage, table);
            form.resetFields();
          }}
        />
      </div>
    </>
  );
}

export default memo(FinalTable);
