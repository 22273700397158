import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, Form, InputNumber, Radio, Select } from "antd";
import React, { useContext, useState } from "react";
import { AppContext } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatesOne, setUpdatesTwo, updateProdCustRow, updateSalesforceRow, updateSAPRow } from "../redux/actions/appActions";
import { COLUMN } from "../utils/columns";
import { usePage } from "../hooks/usePage";
import { getNpsYearOptions } from "../utils/monthUtils";

export const selectAllHandler = (toolbarForm, length, setRowsToBeUpdated) => {
  const values = toolbarForm.getFieldsValue();
  const { tagType } = values;
  const checked = values["selectAll"];
  let checkboxes = null;
  if (tagType === "nps") {
    checkboxes = document.getElementsByClassName("check-row-1");
  } else {
    checkboxes = document.getElementsByClassName("check-row-2");
  }
  if (checkboxes && checkboxes.length)
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = checked;
    }
  if (checked) {
    const temp = Array(length)
      .fill()
      .map((_, i) => i);
    setRowsToBeUpdated(temp);
  } else {
    setRowsToBeUpdated([]);
  }
};

const tagChangeHandler = (toolbarForm, setRowsToBeUpdated, settoolbarValues) => {
  const values = toolbarForm.getFieldsValue();
  const { tagType } = values;
  let checkboxes = [];
  toolbarForm.setFieldValue("selectAll", false);
  toolbarForm.resetFields();
  if (tagType === "nps") {
    toolbarForm.setFieldValue("tagType", "nps");
    checkboxes = document.getElementsByClassName("check-row-2");
  } else {
    toolbarForm.setFieldValue("tagType", "application");
    checkboxes = document.getElementsByClassName("check-row-1");
  }
  if (checkboxes && checkboxes.length)
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  setRowsToBeUpdated([]);
  settoolbarValues(null);
};
function ToolBar({ isOpen, setOpen }) {
  const {
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    setSalesforceRowsTobeUpdated,
    settoolbarValues,
    toolbarForm,
    rowSelectForm,
    rowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    salesforceRowsTobeUpdated,
    currentPage,
    currentPageTwo,
  } = useContext(AppContext);
  const { hasAccessToApp, updatesOne, updatesTwo } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const page = usePage();
  const salesYear = useSelector((state) => (page === "SAP" ? state.selections.salesYear : state.selections.salesYearSf));
  const dispatch = useDispatch();
  const [selectedTagType, setSelectedTagType] = useState(page === "SAP" ? "nps" : "application");
  const data = useSelector((state) => {
    if (page === "salesforce") return state.app.salesforceData;
    else if (selectedTagType === "nps") return state.app.data;
    else return state.app.applicationData;
  });
  const { roles } = useSelector((state) => state.auth);
  const isProdViewer = roles && roles.includes("NPS_PROD_VIEWER");

  const isCurrentPage = (idx, tagType) => {
    const page = tagType === "nps" ? currentPage : currentPageTwo;
    const start = (page - 1) * 20;
    const end = page * 20;
    if (idx >= start && idx <= end) return true;
    return false;
  };
  const fillFormValues = (form, toolbarForm, target, table, updatedRows, updates, dataType = "SAP") => {
    const formUpdates = form.getFieldsValue();
    const toolbarValues = toolbarForm.getFieldsValue();
    if (updatedRows && updatedRows.length) {
      updatedRows.forEach((row) => {
        if (!updates[`${row}`]) {
          updates[`${row}`] = {
            HashKey: data[row].HashKey,
            TaggedBy: user.fullname,
          };
        }
        if (isCurrentPage(row, toolbarValues["tagType"])) {
          formUpdates[`${target.id}-${table}-${row}`] = toolbarValues[target.id];
        }
        // setting default values if nps or application is set to yes on the toolbar.
        if (
          (target.id === COLUMN.QualifyAsApplication || target.id === COLUMN.QualifyAsNPS) &&
          toolbarForm.getFieldValue(target.id) === "YES"
        ) {
          if (isCurrentPage(row)) {
            formUpdates[`${COLUMN.NPSPercentageNew}-${table}-${row}`] =
              updates[`${row}`]?.NPSPercentageNew || data[row].NPSPercentageNew || 0;
            formUpdates[`${COLUMN.NPSPercentageRenew}-${table}-${row}`] =
              updates[`${row}`]?.NPSPercentageRenew || data[row].NPSPercentageRenew || 0;
            formUpdates[`${COLUMN.NPSYear}-${table}-${row}`] = updates[`${row}`]?.NPSYear || data[row].GMIDYear;
            formUpdates[`${COLUMN.NPSCriteria}-${table}-${row}`] = updates[`${row}`]?.NPSCriteria || data[row].NPSCriteria;
          }
          if (target.id === COLUMN.QualifyAsNPS) updates[`${row}`].QualifyAsApplication = "NO";
          if (target.id === COLUMN.QualifyAsApplication) updates[`${row}`].QualifyAsNPS = "NO";
          updates[`${row}`].NPSPercentageNew = updates[`${row}`]?.NPSPercentageNew || data[row].NPSPercentageNew || 0;
          updates[`${row}`].NPSPercentageRenew = updates[`${row}`]?.NPSPercentageRenew || data[row].NPSPercentageRenew || 0;
          updates[`${row}`].NPSYear = updates[`${row}`]?.NPSYear || data[row].GMIDYear;
          updates[`${row}`].NPSCriteria = updates[`${row}`]?.NPSCriteria || data[row].NPSCriteria;
        }
        updates[row][target.id] = toolbarForm.getFieldValue(target.id);
        if (dataType === "SAP") {
          if (selectedTagType === "nps") dispatch(updateSAPRow(updates[row], row));
          else dispatch(updateProdCustRow(updates[row], row));
        } else {
          dispatch(updateSalesforceRow(updates[row], row));
        }
      });
    }
    form.setFieldsValue(formUpdates);
    console.log(formUpdates, form.getFieldsValue());
    return updates;
  };
  const onChangeHandler = (form, toolbarForm, target, table) => {
    console.log(rowsToBeUpdated, rowsToBeUpdatedForTableTwo, salesforceRowsTobeUpdated);
    if (rowsToBeUpdated && rowsToBeUpdated.length) {
      const _updates = fillFormValues(form, toolbarForm, target, table, rowsToBeUpdated, updatesOne, "SAP");
      dispatch(setUpdatesOne(_updates));
    }
    if (rowsToBeUpdatedForTableTwo && rowsToBeUpdatedForTableTwo.length) {
      const _updates = fillFormValues(form, toolbarForm, target, table, rowsToBeUpdatedForTableTwo, updatesTwo, "SAP");
      dispatch(setUpdatesTwo(_updates));
    }
    if (salesforceRowsTobeUpdated && salesforceRowsTobeUpdated.length) {
      const _updates = fillFormValues(form, toolbarForm, target, table, salesforceRowsTobeUpdated, updatesTwo, "Salesforce");
      dispatch(setUpdatesTwo(_updates));
    }
  };
  return (
    <>
      {isOpen && hasAccessToApp ? (
        <div
          className="toolbar-drawer p-2 bg-gray-300 w-[58vw] z-50 sticky top-[90px] right-0 ml-[42vw] rounded-bl-[50px]"
          style={{
            marginTop: -100,
            marginBottom: -60,
          }}
        >
          <Form
            form={toolbarForm}
            layout="vertical"
            onBlur={(e) => {
              onChangeHandler(rowSelectForm, toolbarForm, e.target, toolbarForm.getFieldValue("tagType") === "nps" ? 1 : 2);
            }}
            onSelect={(e) => {
              onChangeHandler(rowSelectForm, toolbarForm, e.target, toolbarForm.getFieldValue("tagType") === "nps" ? 1 : 2);
            }}
            disabled={isProdViewer}
          >
            <div className="flex flex-row justify-start items-start">
              <div
                className="toolbar-close-btn h-min  flex flex-row items-center justify-center"
                onClick={() => {
                  settoolbarValues(toolbarForm.getFieldsValue());
                  setOpen(false);
                }}
              >
                <h4>
                  Tools&nbsp; <CloseOutlined />
                </h4>
              </div>
              <div className="flex flex-row justify-start gap-x-5 w-full">
                <div className="flex flex-col">
                  <Form.Item name="selectAll" style={{ marginBottom: 0 }} valuePropName="checked">
                    <Checkbox
                      title="Select All"
                      onChange={() => {
                        if (page === "SAP") {
                          if (toolbarForm.getFieldValue("tagType") === "nps")
                            selectAllHandler(toolbarForm, data?.length, setRowsToBeUpdated);
                          else selectAllHandler(toolbarForm, data?.length, setRowsToBeUpdatedForTableTwo);
                        } else {
                          selectAllHandler(toolbarForm, data?.length, setSalesforceRowsTobeUpdated);
                        }
                      }}
                    >
                      {!toolbarForm.getFieldValue("selectAll") ? "Select All" : "Deselect All"} (
                      {Math.max(rowsToBeUpdated.length, rowsToBeUpdatedForTableTwo.length) || salesforceRowsTobeUpdated.length || 0})
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="tagType" initialValue={selectedTagType}>
                    <Radio.Group
                      initialValue={selectedTagType}
                      value={selectedTagType}
                      onChange={({ target: { value } }) => {
                        setSelectedTagType(value);
                        if (value === "nps") tagChangeHandler(toolbarForm, setRowsToBeUpdatedForTableTwo, settoolbarValues);
                        else tagChangeHandler(toolbarForm, setRowsToBeUpdated, settoolbarValues);
                      }}
                    >
                      <div className="flex flex-col">
                        {page === "SAP" ? <Radio value="nps">NPS</Radio> : null}
                        <Radio value="application">Application</Radio>
                      </div>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 items-end grid-rows-2 gap-x-2">
                  <Form.Item
                    name={toolbarForm.getFieldValue("tagType") === "application" ? "QualifyAsApplication" : "QualifyAsNPS"}
                    label={toolbarForm.getFieldValue("tagType") === "application" ? "Qualify as ADI:" : "Qualify as NPS:"}
                  >
                    <Select
                      allowClear
                      style={{
                        width: 110,
                        height: 30,
                      }}
                      placeholder={toolbarForm.getFieldValue("tagType") === "application" ? "Application" : "NPS"}
                      options={[
                        { label: "YES", value: "YES" },
                        { label: "NO", value: "NO" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name="NPSPercentageNew" label="NPS New %:">
                    <InputNumber
                      allowClear
                      type="number"
                      style={{
                        width: 110,
                        height: 30,
                      }}
                      placeholder="NPS New %"
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                  <Form.Item name="NPSPercentageRenew" label="NPS Renew %:">
                    <InputNumber
                      allowClear
                      type="number"
                      style={{
                        width: 110,
                        height: 30,
                      }}
                      placeholder="NPS New %"
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                  <Form.Item name="NPSCriteria" label="NPS Criteria:">
                    <Select
                      allowClear
                      style={{
                        width: 110,
                        height: 30,
                      }}
                      placeholder="NPS Criteria"
                      options={[
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name="SustnAdvgedSales" label="Sust Adv Sales:">
                    <Select
                      allowClear
                      style={{
                        width: 110,
                        height: 30,
                      }}
                      placeholder="Sust Adv Sales"
                      options={[
                        { label: "YES", value: "YES" },
                        { label: "NO", value: "NO" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name="SustnAdvgedSalesPercentage" label="% Sust Adv Sales:">
                    <InputNumber
                      allowClear
                      style={{
                        width: 110,
                        height: 30,
                      }}
                      placeholder="% Sust Adv Sales"
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                  <Form.Item name="NPSYear" label="NPS Year/Intro Yr :">
                    <Select
                      allowClear
                      style={{
                        width: 110,
                        height: 30,
                      }}
                      placeholder="Intro/NPS year"
                      options={getNpsYearOptions(salesYear)}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      ) : null}
    </>
  );
}

export default ToolBar;
